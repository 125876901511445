<template>
  <div>
    <div class="top-btn">
      <el-button @click="dialogFormVisible = true">维护</el-button>
    </div>
    <div class="list params-wrap ">
      <div class="list-top">
          <div class="top-up cfx">
            <div class="schoolName fl">中国科技大学</div>
            <div class="main fl">已维护</div>
          </div>
          <div class="top-down cfx ">
            <ul>
              <li><span>标识码</span> 5678999</li>
              <li><span>主管部门</span> 某教育部门</li>
              <li><span>教育层次</span> 本科</li>
              <li><span>所属地区</span> 安徽省-合肥市-高新区</li>
              <li><span>详细地址</span> 安徽省-合肥市-高新区-啦啦去哒哒哒哒哒哒多多多多多多</li>
            </ul>
          </div>
      </div>
      <paged-list v-model="queryParams" :data="pageData" :total="10"  >
        <template slot-scope="scope">
          <div class="box-style-a ">
            <div class="title">{{ scope.row.name }}-校区</div>
            <div class="content cfx ">
              <ul >
                <li class="li1"><span>教育层次</span> 本科</li>
                <li><span>标识码</span> 5678999</li>
                <li><span>主管部门</span> 某教育部门</li>
                <li><span>所属地区</span> 安徽省-合肥市-高新区</li>
                <li><span>详细地址</span> 安徽省-合肥市-高新区-啦啦去哒哒哒哒哒哒多多多多多多</li>
              </ul>
            </div>
          </div>
        </template>
        <template slot="control">
          <el-button type="primary" icon="el-icon-edit" circle></el-button>
          <el-button type="danger" icon="el-icon-delete" circle></el-button>
        </template>
      </paged-list>
    </div>
    <!-- 维护 -->
    <el-dialog title="学校信息维护" center :visible.sync="dialogFormVisible">
      <el-form :model="form" label-width="100px">
        <el-form-item label="学校名称" >
          <el-input style="width:490px" placeholder="中国科学技术大学" clearable></el-input>
        </el-form-item>
        <el-form-item label="所属地区" >
          <el-select  clearable filterable placeholder="请选择省份" style="width:150px">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
          <el-select clearable filterable placeholder="请选择城市" style="width:150px;margin-left:20px;">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
          <el-select  placeholder="请选择区" style="width:150px;margin-left:20px;" clearable filterable>
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否多校区" >
          <el-radio  label="1">是</el-radio>
          <el-radio  label="2">否</el-radio>
        </el-form-item>
        <div class="myTeam">
          <div class="myTeam-items" v-for="(team,index) in teamArr" :key="index">
            <el-form-item :label="'校区' + (index +1)" >
              <el-input style="width:240px;" placeholder="中国科学技术大学" clearable></el-input>
              <el-input style="width:230px; margin-left:20px;" v-model="team.a" placeholder="校区" clearable></el-input>
              <i class="el-icon-circle-plus-outline" v-if="index == 0" @click="addTeam" style=" margin-left:10px; color:#28B892; font-size:20px;"></i>
              <i class="el-icon-remove-outline" v-if="index > 0" @click="removeTeam" style=" margin-left:10px; color:red; font-size:20px;"></i>
            </el-form-item>
            <el-form-item :label="'地址' + (index +1)" >
              <el-input style="width:490px;"   v-model="team.b" placeholder="请输入地址" clearable>
                <i slot="suffix" class="el-icon-map-location" style="color:#28B892;"></i>
              </el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PagedList from "@/components/common/PagedList";
  export default {
      components: {
      PagedList
      },
      data() {
        return {
          dialogFormVisible:false,
          queryParams: {
            schoolName:''
          },
          form:{
            a:[],
            b:[]
          },
          pageData:[
            { name:'中国科技大学' },
            { name:'中国科技大学' },
            { name:'中国科技大学' },
            { name:'中国科技大学' },
            { name:'中国科技大学' }
          ],
          teamArr:[],
          team:{
            a:'',
            b:''
          }
        }
      },
      methods:{
          // 初始化地址、校区
          initPage() {
            this.teamArr = [{ a: "", b: "",}];
          },
          // 添加校区，地址
          addTeam() {
            if (this.teamArr.filter((item) => !item.a || !item.b).length) {
              this.$message.warning("请完善成员信息再添加！");
              return;
            }
            this.teamArr.push({ team: "" });
          },
          // 删除校区 地址
          removeTeam(index) {
            this.teamArr = this.teamArr.filter((item, idx) => idx != index);
          },
      },
      mounted() {
        this.initPage();
      },
  }
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
*{font-family: PingFangSC-Regular, PingFang SC;}
.top-btn{margin: 10px 0px;
  .el-button{width: 150px; background: $c-white;}
}

.list{
  .list-top{min-height: 80px;  border-bottom: 1px solid $c-border; 
    .top-up{
      .schoolName{height: 20px; min-width: 100px; line-height: 20px; font-size: 20px; font-weight: 600;}
      .main{width: 50px; height: 20px; line-height: 20px; text-align: center; color: $c-white; background: $c-success; border-radius: 3px; margin-left: 20px;}
    }
    .top-down{
      ul{ margin-top: 20px;
        li{float:left; margin-right: 60px; text-overflow:ellipsis; white-space:nowrap; overflow:hidden; font-weight: 400; 
          span{font-size: 14px; color: $c-9; font-weight: 400; margin-right: 10px;}
          }
      }
    }
  }
}

</style>